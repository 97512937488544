
.playground-section {
    /* background-color: #05071E; */
}
.playground-item-container {
    background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.05) 100%);
    border: 1.5px solid rgba(255, 255, 255, 0.1);
    border-radius: 30px;
}

.chatbot-container {
    display: flex;
    flex-direction: column; 
    height: 100vh;
    width: 100vw;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

/* Header Styles */
.header {
    height: 45px;
    background-color: var(--user-message-color);
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    color: white;
    font-weight: bold;
}

/* Chat Div */
.chat-container {
    padding: 20px 10px 20px 12px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
}

#chat-history {
    overflow-y: auto;
    flex-grow: 1;
}

#chat-history::-webkit-scrollbar, .auto-prompts-container::-webkit-scrollbar {
    width: 0.5em;
}

#chat-history::-webkit-scrollbar-track, .auto-prompts-container::-webkit-scrollbar-track{
    background-color: transparent;
}

#chat-history::-webkit-scrollbar-thumb, .auto-prompts-container::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 5px;
}

/* Auto Prompts Container */ 
.auto-prompts-container {
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
    flex-wrap: wrap; /* Allow wrapping */
    align-content: flex-start; /* Align content to the start */
    overflow: auto; /* Hide overflow */
    overflow-y: scroll;
    /* max-height: calc(2 * (1em + 10px + 2px)); /* Limit to two lines */
    padding-bottom: 8px; 
}

.auto-prompt {
    flex-shrink: 0; /* Prevent shrinking of the items */
    padding: 9px;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    white-space: nowrap; /* Prevent text wrapping inside .auto-prompt */
}


.auto-prompt p {
    font-size: 14px;
    margin: 0;
}

/* Message Containers */
.bot-message-container,
.user-message-container {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.bot-message,
.user-message {
    background-color: var(--user-message-color);
    padding: 10px;
    margin-left:8px;  
    border-radius: 10px 10px 10px 10px;
    min-width: 80px;
    max-width: 80%;
    font-size: 14px;
    line-height: 20px;
    box-sizing: border-box;
}

.bot-message {
    background-color: #E9ECF0;
    color: rgba(0, 0, 0, 0.7);
}

.user-message {
    background-color: var(--user-message-color);
    color: #fff;
}

/* Input and Send Button */
.input-container {
    display: flex;
    border-radius: 7px;
    padding: 4px 5px;
    /* margin: 0px 8px 3px 12px; */
    box-sizing: border-box;
}

.flex-1 {
    flex: 1;
}
#message-input{
    background: none;
}

.no-border-input {
    width: 100%;
    border-radius: 10px;
    font-size: 14px;
    height: 35px;
    padding-right: 5px;
    border: none;
}
.no-border-input:focus {
    outline: none;
    border-color: transparent;
    box-shadow: none;
}

.send-button-container {
    background-color: var(--user-message-color);
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 0 5px;
}

#send-btn {
    background-color: transparent; /* Remove background color */
    border: none; /* Remove border */
    outline: none; /* Remove focus outline */
    cursor: pointer; /* Set cursor to pointer when button is enabled */
}
#send-btn:disabled {
    cursor: not-allowed; /* Set cursor to not-allowed when button is disabled */
}

.horizontal-flex {
    display: flex;
    flex-direction: row;
}
.justify-center {
    justify-content: center;
}
.align-center {
    align-items: center;
}
.watermark-text {
    /* color: rgba(0, 0, 0, 0.5); */
    color: #9497A3;
    font-size: 12px;
    margin: 0px;
    margin-top: 5px;
    margin-bottom: 7px;
    margin-right: 5px;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    width: fit-content;
    transition: 0.3s all ease;
}

.watermark-text:hover {
    color: rgba(0, 0, 0, 0.8);
    transition: 0.3s all ease;
}

.addy-link {
    cursor: pointer;
    border-bottom: 0.5px solid transparent;
}
.addy-link:hover {
    border-bottom: 0.5px solid rgba(0, 0, 0, 1);
}

/* Profile Photos */
.bot-profile-photo{
    background-color: var(--user-message-color); 
}
.bot-profile-photo,
.user-profile-photo {
    width: 45px;
    height: 45px; 
    overflow: hidden;   
    border-radius: 50%; 
} 
.bot-profile-photo > img{
    background-color: var(--user-message-color); 
    width: 35px; 
    height: 35px;
    padding-top: 5px;
    padding-left: 5px; 
}
.user-profile-photo > img {
    width: 45px; 
    height: 45px; 
}

/* Loading Overlay */
.loading-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.spinner {
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-top-color: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s ease-in-out infinite;
}
.addy-logo {
    margin-right: 2px;
    margin-top: 1px;
}

.image-switch img.hidden {
    display: none;
}

.image-switch:hover img.visible {
    display: none;
}

.image-switch:hover img.hidden {
    display: block;
}

.footer-link {
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-direction: row;
    color: transparent;
}

.footer-context {
    width: 0px;
    height: 0px;
    font-size: 0px;
    color: transparent;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
