.navbar>.active {
    border-bottom: 1px solid black;
}

@font-face {
    font-family: "SF-Pro-Display-Regular";
    src: local("SF-Pro-Display-Regular"), url(./fonts/SFProOTF/SF-Pro-Display-Regular.otf) format("truetype");
}

@font-face {
    font-family: "SF-Pro-Display-Bold";
    src: local("SF-Pro-Display-Bold"), url(./fonts/SFProOTF/SF-Pro-Display-Bold.otf) format("truetype");
}

@font-face {
    font-family: "SF-Pro-Rounded-Semibold";
    src: local("SF-Pro-Rounded-Semibold"), url(./fonts/SFProOTF/SF-Pro-Rounded-Semibold.otf) format("truetype");
}

@font-face {
    font-family: "SF-Pro-Rounded-Light";
    src: local("SF-Pro-Rounded-Light"), url(./fonts/SFProOTF/SF-Pro-Rounded-Light.otf) format("truetype");
}

@font-face {
    font-family: "SF-Pro-Rounded-Thin";
    src: local("SF-Pro-Rounded-Thin"), url(./fonts/SFProOTF/SF-Pro-Rounded-Thin.otf) format("truetype");
}

@font-face {
    font-family: "SF-Pro-Display-Light";
    src: local("SF-Pro-Display-Light"), url(./fonts/SFProOTF/SF-Pro-Display-Light.otf) format("truetype");
}

html {
    /* background: url("./img/about_us_background.png") no-repeat center center fixed;  */
    background-color: #ffffff;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-repeat: no-repeat;
    background-size: cover;
    overflow-x: hidden;
}

body {
    background-color: #ffffff;
    overflow-x: hidden;
}

.SendButtonDiv {
    border-left: 1px solid gray;
    width: 100%;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 7px;
    padding-left: 7px;
    padding-right: 3px;
    height: 100%;
    cursor: pointer;
}

.Background {
    background-color: #ffffff;
    /* background-color: #1F1E21;*/
    /* background-color: #1C1B1C */
    /* background-color: black; */
}

#MainImagePromo {
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    /* box-shadow: 0px 4px 38px rgba(0, 0, 0, 0.3); */
    border-radius: 20px;
    background-color: transparent;
}

#MainImagePromoElevated {
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    box-shadow: 0px 4px 38px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    background-color: transparent;
}

.HeaderOptionsDiv {
    height: fit-content;
    width: fit-content;
}
/* For Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

/* For IE and Edge */
.hide-scrollbar {
    -ms-overflow-style: none; 
}

/* For Firefox */
.hide-scrollbar {
   scrollbar-width: none;
}


.box:before {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(-25deg, #745DDE 0%, #F3F3F5 100%);
    transform: translate3d(-2px, -5px, 0) scale(0.93);
    filter: blur(60px);
    opacity: var(0.1);
    transition: opacity 0.3s;
    border-radius: 30px;
}

/* 
* Prevents issues when the parent creates a 
* stacking context. (For example, using the transform
* property )
*/
.box::after {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: inherit;
    border-radius: inherit;
}

.site-announcement {
    width: fit-content;
    font-size: 14px;
    background-color: #28293F;
    color: #FFFFFF;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 30px;
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0.7);
    cursor: pointer;
}

.HeaderOptionButton {
    /* color: #6D53AF; */
    color: rgba(0, 0, 0, 0.8);
    transition: 0.3s all ease;
    margin: 0px;
    font-size: 20px;
    font-family: SF-Pro-Display-Light;
}

.HeaderOptionButton:hover {
    /* color: #2B1C50; */
    transition: 0.3s all ease;
    text-decoration: underline;
    cursor: pointer;
}

.AppTitleText {
    font-family: SF-Pro-Display-Bold;
    font-style: normal;
    margin: 0;
    font-weight: normal;
    padding-top: 4px;
    font-size: 21px;
    letter-spacing: 0.4px;
    text-align: left;
    color: #745dde;
}

.AppTitleMarker {
    /* background-color: #EA8B6F; */
    background-color: transparent;
    /* padding-left: 8px;
    padding-right: 8px; */
    border-radius: 4px;
    margin: 0;
    /* padding-bottom: 1px;
    padding-top: 1px; */
    padding: 0px;
    color: rgba(255, 255, 255, 1);
}

.AppTitleMarker2 {
    /* background-color: #EA8B6F; */
    background-color: transparent;
    /* padding-left: 8px;
    padding-right: 8px; */
    border-radius: 4px;
    margin: 0;
    /* padding-bottom: 1px;
    padding-top: 1px; */
    padding: 0px;
    color: #b4a4fa;
    /* color: #c50924; */
    /* color: #0EBC00; */
}

.AppLogo {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.Header {
    /* position: fixed; */
    z-index: 100;
    top: 0;
    /* background: #121212; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
}

.space-between {
    justify-content: space-between;
}

.SubtitleMainCoverTitle1 {
    font-family: SF-Pro-Rounded-Light;
    /* font-family: SF-Pro-Rounded-Thin; */
    /* font-family: SF-Pro-Display-Regular; */
    /* width: 90%; */
    text-align: left;
    /* border: 1px solid yellow; */
    font-style: normal;
    margin: 0;
    overflow-wrap: break-word;
    padding-top: 8px;
    padding-bottom: 2px;
    font-weight: bold;
    font-size: var(--subTitleSize);
    letter-spacing: 0.4px;
    color: rgba(255, 255, 255, 0.6);
}

.FooterTextCelestial {
    font-family: SF-Pro-Display-Regular;
    margin: 0px;
    font-size: 16px;
    padding-left: 40px;
    /* color: #0B3558; */
    /* color: rgba(0, 0, 0, 0.8); */
    text-decoration: none;
    color: #4E5E70;
}

.FooterSectionTitle {
    font-family: SF-Pro-Display-Bold;
    margin: 0px;
    font-size: 16px;
    padding-left: 40px;
    /* color: #0B3558; */
    /* color: rgba(0, 0, 0, 0.8); */
    text-decoration: none;
    color: #4E5E70;
}

.FooterSectionTitleNoLeftPadding {
    font-family: SF-Pro-Display-Bold;
    margin: 0px;
    font-size: 16px;
    /* color: #0B3558; */
    /* color: rgba(0, 0, 0, 0.8); */
    text-decoration: none;
    color: #4E5E70;
}

.customer-reel {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    overflow: visible;
    animation: scroll 40s linear infinite;
}

.customer-details-container {
    padding-top: 10px;
    padding-bottom: 10px;
    /* padding-left: 30px; */
    padding-right: 30px;
}

.customer-reel-logo {
    width: 300px;
    height: 100px;
    object-fit: contain;
    display: inline-block;
}

.customer-name {
    font-family: SF-Pro-Display-Regular;
    width: auto;
    display: inline-block;
    height: fit-content;
    text-align: center;
    overflow-wrap: break-word;
    margin: 0;
    white-space: nowrap;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 30px;
    padding-left: 30px;
    font-weight: bold;
    font-size: 30px;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.7);
}

/* create a keyframe animation that moves the reel to the left */
@keyframes scroll {
    0% {
        transform: translateX(20%);
        /* move the reel to the right */
    }

    50% {
        transform: translateX(-50%);
    }

    100% {
        transform: translateX(-100%);
        /* move the reel to the right */
    }
}

.customer-reel img {
    flex-shrink: 0;
    /* ensure images don't shrink */
}

.Footer-link {
    /* color: #6D53AF; */
    transition: 0.3s all ease;
}

.Footer-link:hover {
    color: #4E5E70;
    ;
    text-decoration: underline;
    transition: 0.3s all ease;
}

.SubtitleMainCoverTitle2 {
    /* font-family: SF-Pro-Rounded-Thin; */
    font-family: SF-Pro-Rounded-Light;
    /* width: 100%; */
    text-align: left;
    /* border: 1px solid yellow; */
    font-style: normal;
    margin: 0;
    padding-bottom: 2px;
    font-weight: bold;
    overflow-wrap: break-word;
    font-size: var(--subTitleSize);
    letter-spacing: 0.4px;
    color: rgba(255, 255, 255, 0.6);
}

.CofoundersLink {
    /* text-decoration: underline; */
    background: none;
    color: rgba(255, 255, 255, 0.7);
}

.NotifiedMainCoverTitle {
    /* font-family: SF-Pro-Rounded-Light; */
    font-family: SF-Pro-Display-Regular;
    width: 100%;
    text-align: left;
    /* border: 1px solid yellow; */
    font-weight: bold;
    font-style: normal;
    margin: 0;
    padding-bottom: 10px;
    padding-top: 30px;
    font-size: 40px;
    /* color: rgba(255, 255, 255, 1); */
    color: #2b1c50;
}

.SocialsMainCoverTitle {
    font-family: SF-Pro-Display-Bold;
    /* font-family: SF-Pro-Display-Regular; */
    width: 100%;
    text-align: center;
    /* border: 1px solid yellow; */
    font-style: normal;
    margin: 0;
    padding-bottom: 5px;
    padding-top: 30px;
    font-weight: bold;
    font-size: 25px;
    letter-spacing: 0.4px;
    /* color: rgba(255, 255, 255, 1); */
    color: #ffffff;
}

.SocialsText {
    font-family: SF-Pro-Display-Regular;
    /* font-family: SF-Pro-Display-Regular; */
    width: 100%;
    text-align: center;
    /* border: 1px solid yellow; */
    font-style: normal;
    margin: 0;
    font-size: 20px;
    font-weight: normal;
    letter-spacing: 0.4px;
    color: rgba(255, 255, 255, 0.9);
}

.ResearchText {
    font-family: SF-Pro-Display-Regular;
    /* font-family: SF-Pro-Display-Regular; */
    width: 100%;
    text-align: left;
    /* border: 1px solid yellow; */
    font-style: normal;
    margin: 0;
    font-size: 25px;
    font-weight: normal;
    text-decoration: underline;
    letter-spacing: 0.4px;
    color: rgba(255, 255, 255, 0.9);
}

.SubmitText {
    padding: 0px;
    margin: 0px;
    font-family: SF-Pro-Rounded-Light;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0.4px;
    color: #ffffff;
    font-size: 25px;
    background: #565add;
    /* background-image: linear-gradient(160deg, #0a57d0 0%, #745dde 100%); */
    border-radius: 40px;
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 30px;
    padding-top: 35px;
    padding-bottom: 35px;
    padding-left: 35px;
    padding-right: 35px;
}

.SubmitTextHeader {
    padding: 0px;
    margin: 0px;
    font-family: SF-Pro-Rounded-Light;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0.4px;
    color: #ffffff;
    font-size: 15px;
    background: #565add;
    /* background-image: linear-gradient(160deg, #0a57d0 0%, #745dde 100%); */
    border-radius: 20px;
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
}

#addy-nav-link:hover {
    color: #745DDE;
}


.SubmitTextInstall {
    padding: 0px;
    margin: 0px;
    font-family: SF-Pro-Rounded-Light;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0.4px;
    color: #ffffff;
    font-size: 20px;
}

.SubmitTextInstall2 {
    padding: 0px;
    margin: 0px;
    font-weight: bold;
    color: #ffffff;
    font-size: 18px;

    background: #565add;
    /* background-image: linear-gradient(160deg, #0a57d0 0%, #745dde 100%); */
    border-radius: 40px;
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 30px;
    padding-top: 35px;
    padding-bottom: 35px;
    padding-left: 35px;
    padding-right: 35px;
}

.DownloadBtnDiv {
    padding-left: 20px;
    padding-right: 20px;
}

.SubmitButtonInstall {
    background: #565add;
    border-radius: 8px;
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

.SubmitButtonInstall2 {
    background: "transparent";
    border-radius: 8px;
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
}

.premium-button {
    /* background-color: #745DDE; */
    background-color: #0a57d0;
    background-image: linear-gradient(160deg, #0a57d0 0%, #745dde 100%);
    font-family: Helvetica, sans-serif;
    width: fit-content;
    color: #ffffff;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius: 5px;
    border: 1px solid transparent;
    cursor: pointer;
    transition: 0.3s all ease;
}

.premium-button:hover {
    background-color: #0a57d0;
    /* background-image: linear-gradient(308deg, #0a57d0 0%, #745dde 100%); */
    box-shadow: 0 1px 6px 2px rgba(137, 167, 230, 1);
    transition: 0.3s all ease;
}

.SubmitButton2 {
    background: #565add;
    border-radius: 8px;
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 42px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
    margin-left: 15px;
}

.SubmitText2 {
    padding: 0px;
    margin: 0px;
    font-family: SF-Pro-Rounded-Light;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0.4px;
    color: #ffffff;
    font-size: 25px;
}

.DemoDiv {
    /* background: rgba(62, 60, 60, 0.9); */
    width: 100%;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* backdrop-filter: blur(50px); */
    /* Note: backdrop-filter has minimal browser support */
}

.DemoContainerDiv {
    background: linear-gradient(198.18deg, #1f1f1f 9.55%, #000000 125.88%);
    box-shadow: 0px 4px 38px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
}

.SocialsLink {
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    color: #4E5E70;
    width: fit-content;
    text-decoration: none;
    border-bottom: 2px solid transparent;
    margin-right: 20px;
}

.ResearchLink {
    width: fit-content;
    display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
    border-bottom: 1px solid transparent;
}

.FoundersLink {
    text-decoration: underline;
    border-bottom: 1px solid gray;
    cursor: pointer;
    color: gray;
    text-decoration: none;
}

.FoundersLink:hover {
    border-bottom: 1px solid #ea8b6f;
    text-decoration: none;
    transition: 0.2s all ease;
    color: gray;
}

.SocialsLink:hover {
    border-bottom: 2px solid #FFFFFF;
    text-decoration: none;
    transition: 0.2s all ease;
}

.SocialsLink:active {
    border-bottom: 2px solid white;
    transition: 0.2s all ease;
}

.SocialsDiv {
    display: flex;
    flex-direction: row;
    padding-top: 15px;
    padding-bottom: 40px;
}

.HomeContainer {
    /* background-color: black; */
    background-repeat: no-repeat;
    background-size: cover;
}

.GradientText {
    background: -webkit-linear-gradient(#ea8b6f, #ba35ef);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.GradientText2 {
    /* background: -webkit-linear-gradient(#a267ac, #ffbcd1); */
    /* background: linear-gradient(to right, #fe9678 0%, #ba35ef 51%, #fe9678 100%); */
    background-image: linear-gradient(160deg, #0a57d0 0%, #745dde 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.HeaderContainer {
    /* background-image: url("./img/nav_background.svg"); */
    /* background-color: white; */
    background-color: #FFFFFF;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 5;
    padding-bottom: 5px;
    position: fixed;
    z-index: 99999;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}

.AboutUsContainer {
    /* background-image: url("./img/about_us_background.png"); */
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-size: cover;
}

.LangDriveBody {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
}
.SecurityContainer {
    /* background-color: #FAF5EA; */
    background-repeat: no-repeat;
    background-size: cover;
}

.scroller {
    height: 1.2em;
    line-height: 1.2em;
    position: relative;
    overflow: hidden;
    font-size: 40px;
    text-align: center;
}

.scroller>span {
    position: absolute;
    top: 0;
    animation-name: slide;
    animation-duration: 6s;
    animation-iteration-count: 8;
    font-weight: bold;
    background-color: green;
}

@keyframes slide {
    0% {
        top: 0;
    }

    25% {
        top: -1.2em;
    }

    50% {
        top: -2.4em;
    }

    75% {
        top: -3.6em;
    }
}

.HowItWorksContainer {
    /* background-color: #F3F7FC; */
    /* background-color: #EBF0F8; */
    background-repeat: no-repeat;
    background-size: cover;
}

.FooterContainer {
    /* background-color: #f7faff; */
    /* background-color: #202124; */
    /* background-repeat: no-repeat;
    background-size: cover; */
    border-top-color: rgba(247, 248, 248, 0.2);
    border-top-width: 0.5px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.PrimarySectionContainer {
    background-color: #D1D1F7;
    /* background-color: #ebf0f8; */
    padding-top: 50px;
    padding-bottom: 150px;
    /* min-height: 100vh; */
    
}

.navbar-light .navbar-toggler-icon-addy-dark {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }

.check-mark {
    color: #565add;
    font-size: 21px;
    line-height: 21px;
    margin: 0px;
    padding: 0px;
    margin-right: 4px;
    background-color: transparent;
}

.check-text {
    margin: 0px;
    margin-right: 20px;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.ChecksDiv {
    margin-top: 30px;
}

.CelestialHelpsContainer {
    /* background-color: black;
    background-repeat: no-repeat;
    background-size: cover; */
    /* border: 1px solid red; */
    background: linear-gradient(198.18deg, #1f1f1f 9.55%, #000000 125.88%);
    box-shadow: 0px 4px 38px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
}

.CelestialHelpsTextDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 1px solid yellow; */
    width: 100%;
}

.HelpsDiv {
    /* border: 1px solid red; */
}

.FullParentDimensions {
    width: 100%;
    height: 100%;
}

.RadialGradientHome {
    /* border: 1px solid yellow; */
    position: fixed;
    width: 300px;
    height: 400px;
    right: 0;
    /* top: -300px; */
    top: 0;
    margin-top: 60px;
    z-index: 999999;
    background: radial-gradient(53.9% 53.9% at 77.01% 30.21%, rgba(241, 104, 102, 0.844) 0%, #745DDE 100%);
    opacity: 0.42;
    filter: blur(150px);
    /* rgba(197, 9, 36, 1) */
    /* color: #C50924; */
}

.RadialGradientHelps {
    border: 1px solid yellow;
    position: absolute;
    width: 300px;
    height: 300px;
    left: -150px;
    /* top: -300px; */
    top: 0;
    margin-top: 30px;
    z-index: 250;
    background: radial-gradient(53.9% 53.9% at 77.01% 30.21%, rgba(241, 104, 102, 0.844) 0%, #c50924 100%);
    opacity: 0.42;
    filter: blur(150px);
}

.RadialGradientHow {
    border: 1px solid yellow;
    position: absolute;
    width: 300px;
    height: 300px;
    /* left: -150px; */
    top: -300px;
    /* top: 0; */
    margin-top: 30px;
    z-index: 999999;
    background: radial-gradient(53.9% 53.9% at 77.01% 30.21%, rgba(241, 104, 102, 0.844) 0%, #745DDE 100%);
    opacity: 0.42;
    filter: blur(150px);
}

.RadialGradientWhy {
    border: 1px solid yellow;
    position: absolute;
    width: 300px;
    height: 300px;
    right: 0;
    /* top: -300px; */
    bottom: 0;
    margin-top: 30px;
    z-index: 250;
    background: radial-gradient(53.9% 53.9% at 77.01% 30.21%, rgba(241, 104, 102, 0.844) 0%, #c50924 100%);
    opacity: 0.42;
    filter: blur(150px);
}

.FounderName {
    /* font-family: SF-Pro-Rounded-Light; */
    font-family: SF-Pro-Display-Regular;
    text-align: left;
    width: fit-content;
    /* border: 1px solid white; */
    font-style: normal;
    margin: 0;
    padding: 0px;
    margin-top: 2px;
    font-weight: bold;
    font-size: 22px;
    letter-spacing: 0.4px;
    /* color: rgba(255, 255, 255, 1); */
    color: #ffffff;
}

.FounderInfo {
    font-family: SF-Pro-Display-Regular;
    width: fit-content;
    text-align: left;
    /* border: 1px solid yellow; */
    font-style: normal;
    margin: 0;
    padding: 0px;
    font-weight: lighter;
    font-size: 16px;
    letter-spacing: 0.4px;
    /* color: rgba(255, 255, 255, 1); */
    color: gray;
}

.PaperInfo {
    font-family: SF-Pro-Display-Regular;
    width: fit-content;
    height: 45px;
    text-align: center;
    /* border: 1px solid yellow; */
    font-style: normal;
    margin: 0;
    margin-top: 15px;
    padding: 0px;
    padding-left: 8px;
    padding-right: 8px;
    font-weight: lighter;
    font-size: 16px;
    letter-spacing: 0.4px;
    color: rgba(255, 255, 255, 0.5);
}

.TestimonialDescription {
    font-family: SF-Pro-Display-Light;
    width: fit-content;
    text-align: center;
    /* border: 1px solid yellow; */
    font-style: normal;
    margin: 0;
    margin-top: 15px;
    padding: 0px;
    padding-left: 8px;
    padding-right: 8px;
    font-weight: lighter;
    font-size: 25px;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.8);
}

.TestimonialName {
    font-family: SF-Pro-Display-Bold;
    width: fit-content;
    text-align: center;
    /* border: 1px solid yellow; */
    font-style: normal;
    margin: 0;
    margin-top: 15px;
    padding: 0px;
    font-size: 20px;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.7);
}

.TestimonialTitle {
    font-family: SF-Pro-Display-Light;
    width: fit-content;
    text-align: center;
    /* border: 1px solid yellow; */
    font-style: normal;
    margin: 0;
    padding: 0px;
    font-size: 20px;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.7);
}

.OppositeCenter {
    align-items: center;
}

.FullParentWidth {
    width: 100%;
}

.FounderBio {
    font-family: SF-Pro-Display-Regular;
    width: 100%;
    text-align: left;
    /* border: 1px solid yellow; */
    font-style: normal;
    margin: 0;
    margin-bottom: 20px;
    padding: 0px;
    /* font-weight: bold; */
    font-size: 17px;
    letter-spacing: 0.4px;
    color: rgba(255, 255, 255, 1);
    /* color: transparent; */
    transition: 0.5s all ease;
}

.PaperBio {
    font-family: SF-Pro-Display-Regular;
    width: 100%;
    height: 80px;
    text-align: center;
    /* border: 1px solid yellow; */
    font-style: normal;
    margin: 0;
    padding: 0px;
    /* font-weight: bold; */
    padding-left: 10px;
    padding-right: 10px;
    font-size: 20px;
    letter-spacing: 0.4px;
    color: rgba(255, 255, 255, 0.8);
    transition: 0.5s all ease;
}

.PaddingTeam {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    margin-bottom: 50px;
    /* border: 1px solid yellow; */
}

/* #michael-bio {
    background-color: red;
} */
.TeamMember {
    /* border: 1px solid white; */
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 15px;
    padding-right: 15px;
    /* background: rgba(255, 255, 255, 0.2); */
    /* background: linear-gradient(198.18deg, #1f1f1f 9.55%, #000000 125.88%); */
    box-shadow: 0px 4px 38px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
}

.dark-feature-card {
    background: linear-gradient(198.18deg, #1F1F1F 9.55%, #000000 125.88%);
    box-shadow: 0px 4px 38px rgba(0, 0, 0, 0.15);
}

.feature-card {
    background-color: #F3F9FE;
    border-radius: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 30px;
    width: 92%;

    /* Add transition property for smooth animation */
    transition: transform 0.3s ease-in-out;
}

.feature-card:hover {
    cursor: pointer;

    /* Increase the size of the card on hover using scale */
    transform: scale(1.05);
}

.InfoBoxStandOut {
    /* border: 1px solid white; */
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 25px;
    padding-right: 25px;
    /* background: linear-gradient(198.18deg, #BFB7F1 9.55%, #FFFFFF 125.88%); */
    /* background-color: ; */
    /* box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px; */
    /* box-shadow: 0px 4px 38px rgba(0, 0, 0, 0.15); */
    border-radius: 20px;
}

.question {
    cursor: pointer;
    /* background-color: #f8f9fa; */
    border-radius: 30px;
    margin-bottom: 20px;
}
.answer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.question:hover {
    /* color: rgba(255, 255, 255, 1); */
    color: #4F46AE;
    background-color: #D2E2FB;
    /* background-color: #745DDE;*/
    transition: 0.3s all ease;
}

.question.active .question-title {
    color: rgba(0, 0, 0, 0.9);
}

.question:hover .question-title {
    color: rgba(0, 0, 0, 0.9);
}

.question.active {
    /* background-color: #e9ecef !important; */
    background-color: #D2E2FB !important;
    transition: 0.3s all ease;
    color: rgba(0, 0, 0, 0.9);
    width: 100%;
}

.question.active:hover {
    /* color: #1D344B; */
    color: rgba(0, 0, 0, 0.9);
}

.question-title {
    font-family: SF-Pro-Display-Regular;
    letter-spacing: 0.5px;
    font-size: 25px;
    color: #FFFFFF;
}

.question-description {
    font-family: SF-Pro-Rounded-Light;
    font-size: 20px;
}

.DemoImage {
    border-radius: 20px;
    object-fit: cover;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 1px 0px inset, #BFB7F1 0px 20px 80px 0px, rgba(255, 255, 255, 1) 0px 30px 60px -30px; */
    /* box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px; */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    /* border: 0.5px solid rgba(0, 0, 0, 0.2) */
    /* border: 1px solid #EAE9FB; */
}

.ResearchMember {
    /* border: 1px solid white; */
    /* padding-top: 50px; */
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 40px;
    padding-bottom: 50px;
    background: linear-gradient(198.18deg, #373737 50.55%, #000000 125.88%);
    /* background-color: #373737; */
    box-shadow: 0px 4px 38px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    transition: 0.5s all ease;
    border: 2px solid black;
}

.ResearchMember:hover {
    cursor: pointer;
    transition: 0.5s all ease;
    border: 2px solid #c50924;
}

/* .DemoThumbnail {
    border: 3px solid #C50924;
} */

.ThumbnailLink {
    width: 100%;
}

.DemoThumbnail:hover {
    cursor: pointer;
    /* width: 100%; */
    transition: 0.5s all ease;
}

.TeamMember:hover {
    cursor: pointer;
}

.TeamMember:hover .FounderBio {
    color: #ffffff;
    transition: 0.5s all ease;
}

.FounderImage {
    width: 200px;
    height: 200px;
    /* border-radius: 50%; */
    border-radius: 8px;
    border: 0px solid #c50924;
}

.PaperImage {
    width: 100%;
    height: 300px;
    /* border-radius: 50%; */
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border: 0px solid #c50924;
}

.VerticalFlex {
    display: flex;
    flex-direction: column;
}

.CenterContentsCompletely {
    justify-content: center;
    align-items: center;
}

.Border {
    border: 1px solid yellow;
}

.OSImageContainer {
    /* background: linear-gradient(-45deg, #352384 0%, #F3F3F5 100% ); */
}

.CenterContents {
    justify-content: center;
}

.HorizontalFlex {
    display: flex;
    flex-direction: row;
}

.CenterOppositeDirectionToo {
    align-items: center;
}

.subTextDiv {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 30px;
}

.Elevated {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.7), 0px px 2px rgba(0, 0, 0, 0.8), 0px 1px 3px rgba(0, 0, 0, 0.6);
}

.OsImage {
    transform: scaleX(-1);
    border-radius: 10px;
}

.BorderRadiusImage {
    /* border-radius: 10px; */
    /* box-shadow: 0px 4px 38px rgba(0, 0, 0, 0.15); */
    background-color: transparent;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* background-color: #E8F3FB; */
    background-color: #F6F6FE;
    /* background-color: #D4E6FF; */
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 40px;
    /* border: 1px solid #C50924; */
}

.BorderRadiusImageNoElevation {
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    /* box-shadow: 0px 4px 38px rgba(0, 0, 0, 0.3); */
    /* border: 1px solid #C50924; */
}

.GreyBorder {
    border: 2px solid grey;
    border-radius: 10px;
}

.RedBorder {
    border: 2px solid #c50924;
    border-radius: 10px;
}

.NotRedBorder {
    border: 2px solid #c56776;
    border-radius: 10px;
}

.MainMarker {
    background-color: #ffffff;
    /* background-color: #6867AC; */
    /* background-color: #4D4C7D; */
    /* background-color: #CE7BB0; */
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 4px;
    margin-left: 10px;
    margin-right: 10px;
    /* padding: 0px; */
    color: #11060d;
    /* color: #FFFFFF; */
}

.OverflowBreak {
    overflow-wrap: break-word;
}

.MainMarker1 {
    background-color: #ffffff;
    /* background-color: #6867AC; */
    /* background-color: #4D4C7D; */
    /* background-color: #CE7BB0; */
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 4px;
    margin-right: 10px;
    /* padding: 0px; */
    color: #11060d;
    /* color: #FFFFFF; */
}

.gradientButton {
    margin-top: 10px;
    /* font-family: "Arial Black", Gadget, sans-serif; */
    /* font-size: 18px; */
    /* padding: 15px; */
    text-align: center;
    /* text-transform: uppercase; */
    transition: 0.5s;
    background-size: 200% auto;
    color: #fff;
    box-shadow: 0 0 20px #eee;
    /* width: 280px; */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    cursor: pointer;
    display: inline-block;

    padding-left: 8px;
    padding-right: 8px;
    border-radius: 4px;
    margin-left: 10px;
    margin-right: 10px;
}

.gradientButton:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

/*Pricing styles*/

.pricing-header {
    font-family: SF-Pro-Display-Regular;
    width: 100%;
    color: #282828;
    text-align: center;
    margin-bottom: 10px;
}

.pricing-sub-header {
    font-family: SF-Pro-Display-Regular;
    text-align: center;
}

.subtitle-pricing-text {
    font-family: SF-Pro-Display-Regular;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
}

.loader {
    border: 16px solid #f3f3f3;
    /* Light grey */
    border-top: 16px solid #8574da;
    /* Blue */
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

#loading-overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.3);
}

.pricing-sub-helper {
    background-color: #edf3ec;
    font-family: SF-Pro-Display-Regular;
    font-size: 19px;
    text-align: center;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 8px;
    font-weight: bold;
    letter-spacing: 0.6px;
}

.pricing-sub-helper-2 {
    /* background-color: #edf3ec; */
    font-family: SF-Pro-Rounded-Light;
    font-size: 17px;
    text-align: center;
    color: rgba(0, 0, 0, 0.8);
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 8px;
    letter-spacing: 0.7px;
}

.columns-pricing {
    float: left;
    width: 33.3%;
    padding: 8px;
}

.price {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    color: "#FFFFFF";
}

.price-feature {
    color: #FFFFFF;
}

.price-amount {
    font-size: 30px;
    font-family: SF-Pro-Display-Bold;
    margin-bottom: 20px;
    color: "#FFFFFF"
}

.price-amount-off {
    font-size: 15px;
    font-family: SF-Pro-Display-Regular;
    text-decoration: line-through;
    margin: 0px;
    color: "#FFFFFF"
}

.plan-summary {
    font-size: 16px;
    margin-bottom: 20px;
    font-family: SF-Pro-Display-Regular;
    color: rgba(0, 0, 0, 0.5);
}

.plan-type {
    font-size: 14px;
    margin-bottom: 10px;
    font-family: SF-Pro-Display-Light;
    color: rgba(0, 0, 0, 0.9);
}

.plan-container {
    list-style-type: none;
    margin: 0;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    padding: 20px;
    height: fit-content;
    border-radius: 30px;
    margin-bottom: 50px;
    background-color: rgba(255, 255, 255, 1);
}

.best-deal-container {
    list-style-type: none;
    border: 4px solid #8AB4F8;
    margin: 0;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    padding: 20px;
    height: fit-content;
    border-radius: 30px;
    background-color: rgba(255, 255, 255, 1);
    margin-bottom: 50px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


.plan-container:hover {
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
}

.plan-title {
    color: #282828;
    font-size: 25px;
    font-weight: bold;
    font-family: SF-Pro-Display-Regular;
    margin: 0px;
}

.price li {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 15px;
    font-family: SF-Pro-Display-Regular;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.7);
    text-align: left;
}

.price-interval {
    background-color: transparent;
    font-size: 16px;
    font-family: SF-Pro-Display-Regular;
}

.subscribe-button {
    background-color: #745dde;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    margin-top: 3px;
    width: 100%;
    height: 20px;
    border-radius: 7px;
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    font-family: SF-Pro-Display-Regular;
}

.subscribe-button:hover {
    text-decoration: underline;
    transition: 0.3s all ease;
}

.skeleton-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: fit-content;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 6px;
    margin-bottom: 15px;
    height: 25px;
    position: relative;
    overflow: hidden;
    background-color: white;
}

.fake-skeleton-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: fit-content;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 6px;
    margin-bottom: 15px;
    height: 25px;
    position: relative;
    overflow: hidden;
    background-color: transparent;
}

.highlighting-text {
    margin: 0px;
    font-family: SF-Pro-Display-Regular;
    color: rgba(0, 0, 0, 0.8);
    font-size: 15px;
}

.skeleton-box::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 6px;
    left: 0;
    transform: translateX(-100%);
    /* background-image: linear-gradient(90deg, #745FDE 0, #82A4D5 20%, #84C1D0 60%, rgba(255, 255, 255, 0)); */
    background-image: linear-gradient(90deg,
            rgba(255, 255, 255, 0) 50%,
            rgba(255, 255, 255, 0.4) 60%,
            rgba(255, 255, 255, 0.5) 60%,
            rgba(255, 255, 255, 1));
    /* background-color: linear-gradient(90deg, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.4) 60%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 1)); */
    animation: shimmer 1.6s infinite;
    content: "";
}

@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}

@media only screen and (max-width: 600px) {
    .columns-pricing {
        width: 100%;
    }
}

/*
Workshop styles
*/

.event-title {
    color: rgba(0, 0, 0, 0.8);
    font-size: 30px;
    font-weight: bold;
    font-family: SF-Pro-Display-Bold;
}

.event-subtitle {
    color: rgba(0, 0, 0, 0.8);
    font-size: 20px;
    font-family: SF-Pro-Display-Bold;
    margin: 0px;
}

.event-subtext {
    color: rgba(0, 0, 0, 0.8);
    font-size: 18px;
    font-weight: bold;
    font-family: SF-Pro-Display-Regular;
    margin: 0px;
}

.event-link {
    color: rgba(0, 0, 0, 0.8);
    font-size: 15px;
    font-family: SF-Pro-Rounded-Light;
    margin: 0px;
    text-decoration: underline;
    cursor: pointer;
    max-width: 250px;
    overflow-wrap: break-word;
    min-width: auto;
}

.event-link:hover {
    color: #745DDE
}

.event-footer {
    margin: 0px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 15px;
    font-family: SF-Pro-Display-Regular;
}


.gradientButton4 {
    background-image: linear-gradient(to right, #fe9678 0%, #ba35ef 51%, #fe9678 100%);
}

.gradientButton4:hover {
    background-position: right center;
}

.language-demo {
    background-color: #EEEEEE;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 20px;
    font-size: 14px;
    cursor: pointer;
}

.language-more {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
    margin: 0px;
    font-size: 14px;
    color: #393636;
}

.language-demo:hover {
    /* color: rgba(255, 255, 255, 1); */
    color: #000000;
    background-color: #D2E2FB;
    /* background-color: #745DDE;*/
    /* transition: 0.3s all ease; */
}

.language-demo.active .language-demo-title {
    color: #000000;
}

.language-demo:hover .language-demo-title {
    color: #000000;
}

.language-demo.active {
    /* background-color: #e9ecef !important; */
    background-color: #D2E2FB !important;
    /* color: #1D344B; */
    color: #000000;
}

.language-demo.active:hover {
    /* color: #1D344B; */
    color: #000000;
}

.languages-list-div {
    background-color: #FFFFFF;
    border-radius: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 30px;
}

.image-overlay{
    position: relative;
    width: 100%; /* or any size */
    height: 500px; /* or any size */
    /* background-image: url('https://i.imgur.com/E0dMeFr.jpg'); */
    background-size: cover;
    border-radius: 20px;
    /* background-color: #003C64; */
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.image-overlay::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: rgba(0, 0, 0, 0.7); */
    border-radius: 20px;
}

.loader-new {
    border: 3px solid #f3f3f3;
    /* Light grey */
    border-top: 3px solid rgba(0, 0, 0, 0.2);
    /* darkey grey */
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 0.5s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.cute-dog-background {
    background-image: url('https://i.imgur.com/UlkYiGn.jpg');
    /* Additional style properties for better appearance */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 10px;
    width: 500px;
    height: auto;
}

.yt-video-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 56.25%;
    margin-bottom: 40px;
}

.yt-video-parent .video-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 56.25%;
}

.yt-video-parent .video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;  
    right: 0;
    width: 90%;
    height: 85%;
    margin: auto;
    border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.yt-video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 90%;
    height: 85%;
    margin: auto;
    border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
