input {
    border-bottom: 2px solid #745dde !important;
}

.mdl-card {
    border-radius: 15px !important;
    box-shadow: none !important;
    min-width: 100% !important;
    margin: 0px !important;
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.campaign-button {
    background: #745dde !important;
    border-radius: 8px !important;
}