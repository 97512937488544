.offer-btn-primary {
    background-color: #745dde !important;
    color: #FFFFFF !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding-top: 10px;
    padding-bottom: 10px;
    gap: 15px;
    border: none;
}

.offer-btn-primary:hover {
    background-color: #5b49ab !important;
    color: #FFFFFF !important;
    transition: 0.3s all ease;
}

.offer-skip-btn {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    text-decoration: underline !important;
    transition: 0.3s all ease;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding-top: 10px;
    padding-bottom: 10px;
    /* border: 1px solid rgba(0, 0, 0, 0.2); */
    border-radius: 10px;
}

.offer-checkmark-list {
    list-style: none;
    padding-left: 0;
}

.offer-checkmark-list li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 8px;
    font-size: 14px;
}

h4 {
    display: block !important;
    font-size: 1.33em !important;
    margin-bottom: 1.33em !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    font-weight: 600 !important;
}

h6 {
    display: block !important;
    font-size: 0.95em !important;
    margin-bottom: 2.33em !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    font-weight: 600 !important;
}


.offer-checkmark-list li::before {
    content: '✔';
    font-family: 'FontAwesome';
    position: absolute;
    left: 0;
    top: 0;
    color: #22C55E;
}

.tooltip-exit-button {
    cursor: pointer;
    padding: 4px;
}

.tooltip-exit-button:hover {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
}

.offer-skip-btn:hover {
    color: #745dde;
    transition: 0.3s all ease;
    cursor: pointer;
}

.offer-video-wrapper {
    position: relative;
    padding-top: 10px;
    /* 16:9 Aspect Ratio */
}

.offer-video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}

.offer-special-offer {
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* Added elevation with box-shadow */
}

.offer-special-offer .badge {
    background-color: #FDE047;
    color: #1F2937;
    font-weight: 700;
}

.offer-app-stats {
    color: #6B7280;
    font-size: 14px;
}

.offer-app-stats .fas {
    color: #F59E0B;
}

.offer-radio-input {
    display: none;
}

.offer-radio-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px 10px;
    /* border: 1px solid #745dde; */
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    margin-right: 10px;
    user-select: none;
    gap: 5px
}

.offer-radio-label .offer-radio-custom {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid #745dde;
    position: relative;
}

.offer-radio-label .offer-radio-custom::after {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #745dde;
    transform: scale(0);
    transition: transform 0.2s ease-in-out;
}

.offer-radio-input:checked+.offer-radio-label .offer-radio-custom::after {
    transform: scale(1);
}

.offer-radio-label .text {
    /* color: #745dde; */
    color: rgba(0, 0, 0, 0.7);
    gap: 10px;
    font-size: 13px;
    align-items: center;
}

.offer-radio-label .billing-cadence {
    font-weight: bold;
    font-size: 14px;
}

.offer-radio-label .old-price {
    color: rgba(0, 0, 0, 0.7);
    font-size: 13px;
    text-decoration: line-through;
    font-style: italic;
}

.offer-overlay {
    display: flex;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.offer-container {
    max-width: 550px;
    padding: 20px;
    background-color: #FFFFFF;
    border-radius: 20px;
}
