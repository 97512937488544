.initial-child-container {
    flex: 0 0 auto;
    display: flex;
    min-width: auto;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.customer {
    flex: 0 0 auto;
    min-width: auto;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    animation: scroll 71.68s linear 0s infinite;
    animation-play-state: running;
    animation-delay: 0s;
    animation-direction: normal;
}

.customer-container {
    overflow-x: hidden !important;
    display: flex;
    flex-direction: row !important;
    position: relative;
    width: 100%;
    transform: none;
}

.customer-child {
    transform: none;
    margin-left: 45px;
    margin-right: 45px;
    margin-bottom: 20px;
}