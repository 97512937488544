.navigation {
    background-color: rgb(32, 33, 36);
    color: white;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 15px;
  }

  .navigation-dropdown-div {
    gap: 10px;
    margin-top: 10px;
    padding: 10px;
    background-color: #3A3B3E;
    border-radius: 10px;
    position: absolute;
  }
  
  .nav-links {
    display: flex;
    gap: 20px;
  }

  .nav-link:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  
  .nav-links a {
    color: white;
    text-decoration: none;
  }
  
  .nav-toggle {
    display: none;
    background: none;
    border: none;
    color: white;
    font-size: 1.5em;
    cursor: pointer;
  }
  
  @media (max-width: 600px) {
    .nav-links {
      display: none;
      flex-direction: column;
      width: 100%;
      text-align: center;
      padding-top: 20px;
    }
  
    .nav-links.active {
      display: flex;
    }
  
    .nav-toggle {
      display: block;
    }
  }
  