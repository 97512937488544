.switch {
    width: 300px;
    height: 45px;
    padding-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease;
    border-radius: 8px;
    margin: 0px;
    padding: 0px;
}

.toggle-div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.switch p {
    cursor: pointer;
    line-height: 45px;
    transition: all 0.3s ease;
    color: rgba(0, 0, 0, 0.5);
    margin: 0px;
}

#monthly {
    width: 50%;
    text-align: center;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    font-weight: normal;
    color: #FFFFFF;
    background: #735DDD;
}

#yearly {
    width: 50%;
    font-weight: normal;
    text-align: center;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    /* background-color: red; */
}

.badge {
    background-color: #FDE047;
    color: #1F2937;
    font-weight: 700;
}

/* .pricing-main-div {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.1s linear;
  } */
/* #monthly-pricing {
    visibility: visible;
    opacity: 1;
  }
  #yearly-pricing {
    visibility: visible;
    opacity: 1;
  } */
/* #yearly:hover {
    text-align: left;
  } */
/* #no:checked ~ .switch {
    background: #eb4f37;
  } */

/* #yes:checked ~ .switch label[for=yes] {
    color: #fff;
    background-color: #735DDD;
  }
  #no:checked ~ .switch label[for=no] {
    color: #fff;
    background-color: #735DDD;
  }
  #no:hover {
    color: #fff;
    background-color: #735DDD;
  } */